html, body {
    background-color: #6C757D;
}

.examples {
    font-size: 11px;

    ol {
        padding: 0;
        margin: 0;
        text-align: left !important;
    }
}

.pronunciation
{
    font-size: 12px;
    font-style: italic;
}
